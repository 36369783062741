export enum GoalKeys {
  WarrantyQuote = "wty-free-instant-quote",
  WarrantyDealStarted = "wty-deal-started",
  WarrantyPaymentStepCompleted = "wty-payment-step-complete",
  WarrantyDetailsStepCompleted = "wty-details-step-complete",
  WarrantyCoverageStepCompleted = "wty-coverage-step-complete",
  WarrantyContractStepCompleted = "wty-contract-step-complete",
}

export default {};
