export interface VariationType {
  key: string;
}

export const withDiscountVaration: VariationType = {
  key: "discounted",
};

export const originalVariation: VariationType = {
  key: "original",
};
