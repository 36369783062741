import api from "@modules/api/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import { useUppercaseAlphaNumericMask } from "@common/hooks/useMask";
import {
  CondensedCbbVehicle,
  VehicleLookupResponse,
} from "../types/VehicleLookupResponse";
import vehicleQueryKeys from "../vehicleQueryKeys";

const getVehicleDetails = (
  vin?: string,
  vehicleSearchString?: string
): Promise<VehicleLookupResponse> => {
  const params = new URLSearchParams();
  if (vin) {
    params.append("vin", vin);
  }
  if (vehicleSearchString) {
    params.append("vehicleSearchString", vehicleSearchString.trim());
  }

  return api.get<VehicleLookupResponse>(
    `api/vehicles/cbb-vehicle-lookup?${params.toString()}`
  );
};

export function getCondensedCbbVehicleName(
  year: number,
  make: string,
  model: string,
  trim: string | null | undefined
) {
  return [year, make, model, trim].join(" ").trim();
}

export function useCbbVehicleLookupByVin(vin: string, enabled?: boolean) {
  const { maskPipe: maskUppercaseAlphaNumericPipe } =
    useUppercaseAlphaNumericMask();

  const { data, error, isFetching } = useQuery({
    queryKey: vehicleQueryKeys.cbbSearchByVin(vin),
    queryFn: () => getVehicleDetails(vin),
    enabled:
      enabled !== false &&
      vehicleValidation.vin.validVin(maskUppercaseAlphaNumericPipe(vin) ?? ""),
    staleTime: Infinity,
  });

  return {
    vehiclesFound: data?.vehicles ?? [],
    error,
    isFetching,
  };
}

export function useCbbVehicleLookupByName(vehicleSearchString: string) {
  const minQueryLength = 3;
  const enabled =
    !!vehicleSearchString?.trim() &&
    vehicleSearchString.length >= minQueryLength;
  const { data, error } = useQuery({
    queryKey: vehicleQueryKeys.cbbSearchByVehicle(vehicleSearchString),
    queryFn: () => getVehicleDetails(undefined, vehicleSearchString),
    enabled,
    placeholderData: keepPreviousData,
  });

  return {
    vehicles: data?.vehicles ?? [],
    isLoading: !error && !data,
    error,
  };
}

export function useCbbVehicleLookupByNameByUniqueTrim(
  vehicleSearchString: string
) {
  const { vehicles, isLoading, error } =
    useCbbVehicleLookupByName(vehicleSearchString);

  const uniqueVehiclesByTrim: CondensedCbbVehicle[] = [];

  for (let i = 0; i < vehicles.length; i += 1) {
    const v = vehicles[i];
    const newName = getCondensedCbbVehicleName(v.year, v.make, v.model, v.trim);

    if (!uniqueVehiclesByTrim.some((vu) => vu.name === newName)) {
      uniqueVehiclesByTrim.push({
        year: v.year,
        make: v.make,
        model: v.model,
        trim: v.trim,
        name: newName,
      });
    }
  }

  return {
    vehicles: uniqueVehiclesByTrim,
    isLoading,
    error,
  };
}
