export enum WarrantySegmentationModeName {
  vehicleOwnershipStatus = "vehicleOwnershipStatus",
  approximateBuyingTimeframe = "approximateBuyingTimeframe",
  quoteForm = "quoteForm",
}

export type WarrantySegmentationMode = {
  name: WarrantySegmentationModeName;
};

export const vehicleOwnershipStatusMode: WarrantySegmentationMode = {
  name: WarrantySegmentationModeName.vehicleOwnershipStatus,
};

export const approximateBuyingTimeframeMode: WarrantySegmentationMode = {
  name: WarrantySegmentationModeName.approximateBuyingTimeframe,
};

export const quoteFormMode: WarrantySegmentationMode = {
  name: WarrantySegmentationModeName.quoteForm,
};
