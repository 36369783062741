import LoadingSpinner from "@common/components/LoadingSpinner";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { useRouter } from "next/router";
import {
  PROTECTION_RIGHTRIDE_CA_EMAIL,
  CONDITIONALLY_SUCCESSFUL_CHROME_DECODE,
} from "@common/constants";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import { Trans, useTranslation } from "next-i18next";
import useFeatureFlags from "@modules/feature-flags/hooks/useFeatureFlags";
import { FeatureFlags } from "@modules/feature-flags/types/FeatureFlag";
import InfoModal from "@common/components/InfoModal";
import SlideToggle from "@common/components/SlideToggle";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CarIcon,
  PersonIcon,
} from "@common/components/icons";
import Form from "@common/components/form";
import Button from "@common/components/Button";
import Listbox from "@common/components/form/Listbox";
import customerValidation from "@common/helpers/validations/customerValidation";
import vehicleValidation from "@common/helpers/validations/vehicleValidation";
import Link from "next/link";
import useDebounce from "@common/hooks/useDebounce";
import { useCbbVehicleLookupByNameByUniqueTrim } from "@modules/vehicle/hooks/useCbbVehicleLookup";
import {
  useNullableNumberMask,
  usePhoneNumberMask,
} from "@common/hooks/useMask";
import Combobox from "@common/components/form/Combobox";
import useCreateAftermarketLeadMutation, {
  decodeErrorResponse,
} from "@modules/purchase/hooks/useCreateAftermarketLeadMutation";
import OverlayLoader from "@common/components/OverlayLoader";
import { provinceFilters } from "@modules/purchase/hooks/useEligibleProvinces";
import useProvincesStatusBySourceList from "@modules/purchase/hooks/useProvincesStatusBySourceList";
import { gtmPushData } from "@common/helpers/gtm";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import useUser from "@modules/user/hooks/useUser";
import useVehicleDisplayFuelTypes from "@modules/vehicle/hooks/useVehicleDisplayFuelTypes";
import translate from "@common/helpers/translate";
import { VinInput } from "@common/components/form/vehicle";
import PhoneInput from "@common/components/form/PhoneInput";
import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import {
  aftermarketDetailsStep,
  getStepById,
} from "@modules/purchase/types/PurchaseStep";
import useUtmParamsFromQuery from "@modules/query-string/hooks/useUtmParamsFromQuery";
import getPromoCodeIsValid from "@modules/purchase/api/getPromoCodeIsValid";
import { AftermarketDiscountConvertData } from "@modules/purchase/types/AftermarketDiscountConvertData";
import { withDiscountVaration } from "@modules/core/convert/types/VariationType";
import { GoalKeys } from "@modules/core/convert/enums/goalKeys";
import { ExperienceResult } from "@modules/core/convert/hooks/useConvertExperience";
import { quoteMethods, QuoteMethodEnum } from "@common/enums/QuoteMethod";
import { WarrantySegmentationFormProps } from "./types/WarrantySegmentationFormProps";
import {
  WarrantySegmentationMode,
  vehicleOwnershipStatusMode,
  approximateBuyingTimeframeMode,
} from "./types/WarrantySegmentationMode";

type Props = {
  onChangeMode(newMode: WarrantySegmentationMode): void;
  convertResult?: ExperienceResult<AftermarketDiscountConvertData>;
};

const WarrantyQuoteForm = ({ convertResult, onChangeMode }: Props) => {
  const { isEnabled } = useFeatureFlags();
  const aftermarketQuoteMethodEnabled = isEnabled(
    FeatureFlags.AftermarketQuoteMethod
  );
  const { t } = useTranslation(["common", "purchase"]);
  const router = useRouter();
  const { locale: rlocale, query } = router;
  const locale = rlocale || "en";
  const { user } = useUser();
  const [isLeadFormSubmitting, setIsLeadFormSubmitting] = useState(false);
  const [isQuoteConfirmationModalOpen, setIsQuoteConfirmationModalOpen] =
    useState(false);

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { source: querySource } = useSourceInfoFromQuery();

  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);

  const {
    coverImage,
    sourceAdId,
    sourceUserId,
    sourceSessionId,
    sourceAppId,
    sourceLocationId,
    sourceCtaId,
    vin: buyerVin,
  } = aftermarketPurchaseFromQuery;

  const [formMode, setFormMode] = useState<"ymm" | "vin">("ymm");

  const provincesList = useProvincesStatusBySourceList(
    querySource,
    provinceFilters.isAftermarketEnabled
  );

  const { fuelTypes } = useVehicleDisplayFuelTypes();
  const fuelTypeOptions: { id: number; name: string }[] | null = useMemo(
    () =>
      fuelTypes.length > 0
        ? fuelTypes.map(({ id, name }) => ({
            id,
            name: translate(name, locale),
          }))
        : null,
    [fuelTypes, locale]
  );

  const { unMaskPipe: phoneUnMaskPipe } = usePhoneNumberMask();
  const { maskPipe: numberMaskPipe, unMaskPipe: numberUnMaskPipe } =
    useNullableNumberMask();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues,
    setError,
  } = useFormContext<WarrantySegmentationFormProps>();

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (router.isReady && firstLoad) {
      if (buyerVin) {
        setFormMode("vin");
      }
      setFirstLoad(false);
    }
  }, [buyerVin, firstLoad, router.isReady]);

  // hack: on locale change, re-run the number mask
  useEffect(() => {
    const v = getValues("odometer");
    if (v != null) {
      setValue("odometer", numberMaskPipe(v.replace(/[^\d.]/, "")));
    }
  }, [getValues, numberMaskPipe, router.locale, setValue]);

  const createAftermarketLeadMutation = useCreateAftermarketLeadMutation();

  const utmParams = useUtmParamsFromQuery();

  const [showOverlay, setShowOverlay] = useState(false);

  const sendLeadForm = useCallback(
    (formInput: WarrantySegmentationFormProps, promoCodeId?: string | null) => {
      const {
        firstName: formFirstName,
        lastName: formLastName,
        phone: formPhone,
        email: formEmail,
        province,
        odometer,
        vin,
        yearMakeModel,
        consent,
        quoteMethod,
        selectedApproximateBuyingTimeframe,
        selectedVehicleOwnershipStatus,
      } = formInput;

      if (!province || !odometer) {
        return;
      }
      setIsLeadFormSubmitting(true);
      createAftermarketLeadMutation.mutate(
        {
          firstName: formFirstName,
          lastName: formLastName,
          phone: phoneUnMaskPipe(formPhone),
          email: formEmail,
          province: province.abbr,
          odometer: numberUnMaskPipe(odometer) ?? 0,
          source: querySource,
          vin: formMode === "vin" ? vin ?? null : null,
          year: formMode === "ymm" ? yearMakeModel?.year ?? null : null,
          make: formMode === "ymm" ? yearMakeModel?.make ?? null : null,
          model: formMode === "ymm" ? yearMakeModel?.model ?? null : null,
          trim: formMode === "ymm" ? yearMakeModel?.trim ?? null : null,
          promoCodeId: promoCodeId ?? null,
          isConsentGiven: consent,
          sourceAdId,
          sourceUserId,
          sourceSessionId,
          sourceAppId,
          sourceLocationId,
          sourceCtaId,
          vehicleCoverImage: coverImage,
          quoteMethod: aftermarketQuoteMethodEnabled ? quoteMethod.id : null,
          fuelType:
            formMode === "ymm" ? formInput.fuelType?.name ?? null : null,
          selectedApproximateBuyingTimeframe:
            selectedApproximateBuyingTimeframe ?? null,
          selectedVehicleOwnershipStatus:
            selectedVehicleOwnershipStatus ?? null,
          ...utmParams,
        },
        {
          onSuccess: (data) => {
            const isDiscounted =
              convertResult?.variationKey === withDiscountVaration.key;
            const shouldTrackConversion =
              !purchase || purchase?.products?.length === 0;

            if (shouldTrackConversion) {
              convertResult?.trackConversion(GoalKeys.WarrantyQuote, {
                quoteAvailable: true,
              });
            }

            gtmPushData({
              event: "AftermarketPurchase",
              element: "Landing Page",
              description: "Free Instant Quote",
              user_id: user?.id,
              convert_variation: isDiscounted ? "discounted" : "original",
            });

            hotjar.event(
              isDiscounted
                ? HotjarEvent.AftermarketConvertPurchaseFreeInstantQuote
                : HotjarEvent.AftermarketPurchaseFreeInstantQuote
            );

            if (
              data &&
              data.success === false &&
              data.message === decodeErrorResponse.invalidVin
            ) {
              setError("vin", {
                message: t("common:invalid_vin_please_try_again"),
              });

              setShowOverlay(false);

              if (aftermarketQuoteMethodEnabled) {
                return;
              }
            }

            if (aftermarketQuoteMethodEnabled) {
              setIsQuoteConfirmationModalOpen(true);
            } else {
              let decodedVin: string | undefined = "";
              if (
                data &&
                data.decodedVin &&
                data.success === true &&
                data.message === CONDITIONALLY_SUCCESSFUL_CHROME_DECODE
              ) {
                decodedVin = data.decodedVin;
              }

              // we want to keep the query params as they were passed to us so the next
              // page behaves as it always has (except loginSuccessful)
              const { loginSuccessful, ...restOfQuery } = query;
              router.push({
                pathname: "/purchase/coverage/quote",
                query: {
                  ...restOfQuery,
                  vin:
                    formMode === "vin"
                      ? decodedVin || vin || undefined
                      : undefined,
                  year:
                    formMode === "ymm"
                      ? yearMakeModel?.year ?? undefined
                      : undefined,
                  make:
                    formMode === "ymm"
                      ? yearMakeModel?.make ?? undefined
                      : undefined,
                  model:
                    formMode === "ymm"
                      ? yearMakeModel?.model ?? undefined
                      : undefined,
                  trim:
                    formMode === "ymm"
                      ? yearMakeModel?.trim ?? undefined
                      : undefined,
                  fuelType:
                    formMode === "ymm"
                      ? formInput.fuelType?.name ?? undefined
                      : undefined,
                  buyerProvince: province.abbr,
                  buyerFirst: formFirstName,
                  buyerLast: formLastName,
                  buyerEmail: formEmail,
                  buyerPhoneNumber: phoneUnMaskPipe(formPhone),
                  odometer: numberUnMaskPipe(odometer) ?? 0,
                },
              });
            }
          },
          onError: () => {
            setShowOverlay(false);
          },
          onSettled: () => {
            setIsLeadFormSubmitting(false);
          },
        }
      );
    },
    [
      convertResult,
      coverImage,
      createAftermarketLeadMutation,
      formMode,
      numberUnMaskPipe,
      phoneUnMaskPipe,
      purchase,
      querySource,
      setError,
      sourceAdId,
      sourceAppId,
      sourceCtaId,
      sourceLocationId,
      sourceSessionId,
      sourceUserId,
      t,
      user?.id,
      utmParams,
      aftermarketQuoteMethodEnabled,
      query,
      router,
    ]
  );

  const handleQuoteConfirmationModalClose = () => {
    setIsQuoteConfirmationModalOpen(false);
    onChangeMode(vehicleOwnershipStatusMode);
  };

  const handleFormSubmit: SubmitHandler<WarrantySegmentationFormProps> = (
    formInput: WarrantySegmentationFormProps
  ) => {
    if (formInput.promoCode) {
      setShowOverlay(true);
      getPromoCodeIsValid(formInput.promoCode).then((res) => {
        if (res.error) {
          if (res.error === "Promo Code cannot be found") {
            setError("promoCode", {
              message: t("purchase:promo_code_cannot_be_found"),
            });
          } else if (res.error === "Promo Code has expired") {
            setError("promoCode", {
              message: t("purchase:promo_code_has_expired"),
            });
          }
          setShowOverlay(false);
        } else {
          sendLeadForm(formInput, res.id);
        }
      });
    } else {
      sendLeadForm(formInput);
    }
  };

  const [ymmQuery, setYmmQuery] = useState<string>("");

  const debouncedQuery = useDebounce(ymmQuery, 300);
  const { vehicles: vehiclesFound } =
    useCbbVehicleLookupByNameByUniqueTrim(debouncedQuery);

  const showResumeButton = !!purchase && purchase.currentStepId != null;

  const handleOnResumePurchaseClick = () => {
    if (!purchase) return;

    const purchaseStep = getStepById(
      "aftermarket",
      purchase?.currentStepId ?? aftermarketDetailsStep.id
    );

    if (purchaseStep) {
      router.push({
        pathname: purchaseStep.href,
      });
    }
  };

  const onClickBackButton = () => {
    const selectedApproximateBuyingTimeframe = watch(
      "selectedApproximateBuyingTimeframe"
    );

    if (selectedApproximateBuyingTimeframe) {
      onChangeMode(approximateBuyingTimeframeMode);
      return;
    }

    onChangeMode(vehicleOwnershipStatusMode);
  };

  return (
    <OverlayLoader isLoading={showOverlay}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div className="sm:col-span-2">
            <SlideToggle
              leftLabel={t("common:year_make_model")}
              leftValue="ymm"
              rightLabel={t("common:vin")}
              rightValue="vin"
              value={formMode}
              onChange={setFormMode}
            />
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <span className="p-1 bg-white rounded-full text-2xl">
                <PersonIcon className="text-gray-300" />
              </span>
              {t("common:your_info")}
            </div>
            <Form.Input
              label={t("common:first_name")}
              maxLength={customerValidation.firstName.maxLength}
              feedback={
                errors.firstName?.type === "required" && (
                  <p className="text-error-500">
                    {t("common:form_error_required_first_name")}
                  </p>
                )
              }
              {...register("firstName", {
                required: true,
                validate: customerValidation.firstName.valid,
                maxLength: customerValidation.firstName.maxLength,
              })}
            />
            <Form.Input
              label={t("common:last_name")}
              maxLength={customerValidation.lastName.maxLength}
              feedback={
                errors.lastName?.type === "required" && (
                  <p className="text-error-500">
                    {t("common:form_error_required_last_name")}
                  </p>
                )
              }
              {...register("lastName", {
                required: true,
                validate: customerValidation.lastName.valid,
                maxLength: customerValidation.lastName.maxLength,
              })}
            />
            <Form.Input
              label={t("common:email")}
              maxLength={customerValidation.email.maxLength}
              feedback={
                errors.email?.type === "required" && (
                  <p className="text-error-500">
                    {t("common:form_error_required_email")}
                  </p>
                )
              }
              {...register("email", {
                required: true,
                pattern: customerValidation.email.pattern,
                maxLength: customerValidation.email.maxLength,
              })}
            />
            <PhoneInput
              label={t("common:phone")}
              feedback={errors.phone?.message}
              status={errors.phone ? "error" : "default"}
              {...register("phone", {
                required: {
                  value: true,
                  message: t("common:form_error_required_phone_number"),
                },
                validate: {
                  minLength: (value) =>
                    customerValidation.phoneNumber.valid(
                      phoneUnMaskPipe(value)
                    ) || t("common:phone_min_length"),
                  validateAreaCode: (value) =>
                    customerValidation.phoneNumber.validAreaCode(
                      phoneUnMaskPipe(value)
                    ) || t("common:form_error_invalid_phone_number"),
                },
              })}
            />
            {aftermarketQuoteMethodEnabled && (
              <Controller
                name="quoteMethod"
                control={control}
                rules={{ required: true }}
                defaultValue={quoteMethods[0]}
                render={({ field: { onChange, ref } }) => (
                  <Listbox
                    label={t("purchase:send_me_my_quote_via")}
                    onChange={onChange}
                    selectedItem={watch("quoteMethod")}
                    items={quoteMethods}
                    displayOption={(item) => t(item?.name) ?? ""}
                    displayButton={(item) =>
                      item?.name != null
                        ? t(item.name)
                        : t(quoteMethods[0].name)
                    }
                    keyOption={(p) => p.id}
                    valueOption={(p) => p}
                    buttonRef={ref}
                    feedback={
                      errors.quoteMethod?.type === "required" && (
                        <p className="text-error-500">
                          {t("purchase:quote_type_required")}
                        </p>
                      )
                    }
                  />
                )}
              />
            )}
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <span className="p-1 bg-white rounded-full text-2xl">
                <CarIcon className="text-gray-300" />
              </span>
              {t("common:vehicle_info")}
            </div>
            {formMode === "ymm" && (
              <>
                <Controller
                  name="yearMakeModel"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Combobox
                      label={t("common:year_make_and_model")}
                      items={vehiclesFound}
                      keyOption={(item) => item.name}
                      displayOption={(item) => item?.name ?? ""}
                      displayButton={(item) => item?.name ?? ""}
                      valueOption={(item) => item}
                      selectedValue={value}
                      onChange={onChange}
                      onInputChange={setYmmQuery}
                      hideDropdownBtn
                      feedback={
                        errors.yearMakeModel?.type === "required" && (
                          <p className="text-error-500">
                            {t("common:vehicle_description_required")}
                          </p>
                        )
                      }
                      itemsFilter="none"
                      itemsTextDisplay="full"
                    />
                  )}
                />
                {fuelTypeOptions && (
                  <Controller
                    name="fuelType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, ref } }) => (
                      <Listbox
                        label={t("common:fuel_type")}
                        onChange={onChange}
                        selectedItem={watch("fuelType")}
                        items={fuelTypeOptions}
                        displayOption={(item) => item?.name ?? ""}
                        displayButton={(item) =>
                          item?.name || t("common:select_fuel_type")
                        }
                        keyOption={(p) => p.id}
                        valueOption={(p) => p}
                        buttonRef={ref}
                        feedback={
                          errors.fuelType?.type === "required" && (
                            <p className="text-error-500">
                              {t("common:fuel_type_required")}
                            </p>
                          )
                        }
                      />
                    )}
                  />
                )}
              </>
            )}
            {formMode === "vin" && (
              <VinInput
                name="vin"
                labelOption="short"
                register={register}
                errors={errors}
                required
              />
            )}
            <Form.Input
              label={t("common:odometer")}
              rightIcon={
                <span className="text-light-200 body-3 font-medium">KM</span>
              }
              maxLength={vehicleValidation.odometer.maxLength}
              feedback={
                errors.odometer?.message != null && (
                  <p className="text-error-500">{errors.odometer?.message}</p>
                )
              }
              {...register("odometer", {
                required: {
                  value: true,
                  message: t("common:form_error_required_odometer"),
                },
                maxLength: vehicleValidation.odometer.maxLength,
                min: {
                  value: 1,
                  message: t("purchase:odometer_cannot_be_zero"),
                },
                onChange: (e) => {
                  e.target.value = numberMaskPipe(e.target.value);
                },
              })}
            />
            <Controller
              name="province"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, ref } }) => (
                <Listbox
                  label={t("common:province")}
                  onChange={onChange}
                  selectedItem={watch("province")}
                  items={provincesList}
                  keyOption={(p) => p.id}
                  displayOption={(item) =>
                    item.isEnabled
                      ? t(item.name)
                      : `${t(item.name)} - ${t("common:coming_soon")}`
                  }
                  displayButton={(item) =>
                    item?.name != null
                      ? t(item.name)
                      : t("common:select_province")
                  }
                  disabledOption={(item) => !item.isEnabled}
                  valueOption={(p) => p}
                  buttonRef={ref}
                  feedback={
                    errors.province?.type === "required" && (
                      <p className="text-error-500">
                        {t("common:province_required")}
                      </p>
                    )
                  }
                />
              )}
            />
            <Form.Input
              label={t("purchase:promo_code")}
              maxLength={20}
              feedback={errors.promoCode?.message}
              status={errors.promoCode ? "error" : "default"}
              {...register("promoCode", {
                maxLength: {
                  value: 20,
                  message: t("common:must_be_fewer_characters", {
                    count: 20,
                  }),
                },
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.trim().toUpperCase();
                },
              })}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-4">
          {" "}
          <Form.Checkbox
            label={
              <span className="caption-1 font-medium">
                <Trans
                  t={t}
                  i18nKey="purchase:customer_details_terms_and_conditions"
                  components={{
                    a: (
                      <Link
                        className="text-primary-bold underline"
                        href="/privacy-policy"
                        target="_blank"
                      />
                    ),
                    b: (
                      <Link
                        className="text-primary-bold underline"
                        href="/terms-conditions"
                        target="_blank"
                      />
                    ),
                  }}
                />
              </span>
            }
            feedback={
              errors.consent?.type === "required" && (
                <p className="text-error-500">
                  {t("common:form_error_required_consent")}
                </p>
              )
            }
            {...register("consent", { required: true })}
          />
          {!showResumeButton && (
            <>
              <div className="hidden sm:block text-center">
                <Button
                  type="submit"
                  className="uppercase"
                  size="extra-large"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("purchase:get_my_free_quote")}
                </Button>
              </div>
              <div className="block sm:hidden text-center">
                <Button
                  type="submit"
                  className="uppercase w-full"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("purchase:get_my_free_quote")}
                </Button>
              </div>
              <Button
                fill="link"
                buttonStyle="borderless"
                spacing="tight-hug"
                leftIcon={<ArrowLeftIcon />}
                className="text-primary-bold underline hover:no-underline"
                onClick={() => onClickBackButton()}
              >
                {t("common:go_back")}
              </Button>
            </>
          )}
          {showResumeButton && (
            <div className="flex flex-col items-center space-y-4 mt-6">
              <div className="hidden sm:block text-center">
                <Button
                  type="submit"
                  className="uppercase"
                  size="extra-large"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("purchase:get_my_free_quote")}
                </Button>
              </div>
              <div className="block sm:hidden text-center w-full">
                <Button
                  type="submit"
                  className="uppercase w-full"
                  rightIcon={<ArrowRightIcon />}
                >
                  {t("purchase:get_my_free_quote")}
                </Button>
              </div>
              <Button
                fill="link"
                buttonStyle="borderless"
                spacing="tight-hug"
                leftIcon={<ArrowLeftIcon />}
                className="text-primary-bold underline hover:no-underline"
                onClick={() => onClickBackButton()}
              >
                {t("common:go_back")}
              </Button>
              <Button
                rightIcon={<ArrowRightIcon />}
                onClick={handleOnResumePurchaseClick}
                buttonStyle="borderless"
                fill="link"
                spacing="no-padding"
                className="text-primary-bold underline hover:no-underline"
              >
                {t("purchase:resume_my_purchase")}
              </Button>
            </div>
          )}
        </div>
      </form>
      {aftermarketQuoteMethodEnabled && (
        <InfoModal
          title={t("purchase:we_are_on_it")}
          isOpen={isQuoteConfirmationModalOpen}
          onClose={handleQuoteConfirmationModalClose}
          primaryButtonText={t("common:done")}
        >
          <div className="text-primary-deep">
            <h3>{t("purchase:your_quote_is_being_generated_and_sent")}</h3>
            {watch("quoteMethod")?.id === QuoteMethodEnum.Email ? (
              <div className="body-3 mt-4">
                <Trans
                  t={t}
                  i18nKey="purchase:please_check_your_email_and_junk_folder"
                  values={{
                    email: watch("email"),
                  }}
                  components={{
                    bold: <span className="font-semibold" />,
                  }}
                />
              </div>
            ) : (
              <div className="body-3 mt-4">
                <Trans
                  t={t}
                  i18nKey="purchase:please_check_your_phone"
                  values={{
                    phone: watch("phone"),
                  }}
                  components={{
                    bold: <span className="font-semibold" />,
                  }}
                />
              </div>
            )}
            <div className="caption-2 mt-4">
              <Trans
                t={t}
                i18nKey="purchase:if_you_have_not_received_your_quote"
                values={{ email: PROTECTION_RIGHTRIDE_CA_EMAIL }}
                components={{
                  a: (
                    <Link
                      className="text-primary-bold underline"
                      href={`mailto:${PROTECTION_RIGHTRIDE_CA_EMAIL}`}
                    />
                  ),
                }}
              />
            </div>
          </div>
        </InfoModal>
      )}
      <LoadingSpinner
        isLoading={isLeadFormSubmitting}
        isOverlay
        iconColor="primary-dark"
      />
    </OverlayLoader>
  );
};

export default WarrantyQuoteForm;
