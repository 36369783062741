import { TranslationKeys } from "src/@types/i18next";

export enum QuoteMethodEnum {
  Email = 1,
  Sms = 2,
}

export type QuoteMethod = {
  id: number;
  name: TranslationKeys["common"];
};

export const quoteMethods: QuoteMethod[] = [
  { id: QuoteMethodEnum.Email, name: "common:email" },
  { id: QuoteMethodEnum.Sms, name: "common:phone" },
];

export default QuoteMethod;
