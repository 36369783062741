import { Trans, useTranslation } from "next-i18next";
import Button from "@common/components/Button";
import { ArrowRightIcon } from "@common/components/icons";
import {
  WarrantyHeroEn,
  WarrantyHeroFr,
  BubblesWarrantyEn,
  BubblesWarrantyFr,
} from "@public/images/purchase/coverage";
import { ImageWithTranslation } from "@common/types/ImageWithTranslation";
import classNames from "classnames";
import VehicleCoverImageFrame from "../VehicleCoverImageFrame";

type Props = {
  isRatesAvailable: boolean;
  hasNoExistingPuchase: boolean;
  vehicleInfo: string;
  vehicleCoverImage: string | null | undefined;
  onResumeBtnCLick: () => void;
  onLookUpWarrantyBtnClick: (element: string) => void;
  onAddPromoCodeBtnClick: () => void;
};

const vehicleDefaultImage: ImageWithTranslation = {
  srcEn: WarrantyHeroEn,
  srcFr: WarrantyHeroFr,
  altKey: "purchase:alt_wty_vehicle_img",
};

const bubblesImage: ImageWithTranslation = {
  srcEn: BubblesWarrantyEn,
  srcFr: BubblesWarrantyFr,
  altKey: "purchase:alt_wty_info_img",
};

const WarrantySelectCoverageHeading = ({
  isRatesAvailable,
  hasNoExistingPuchase,
  vehicleInfo,
  vehicleCoverImage,
  onResumeBtnCLick,
  onLookUpWarrantyBtnClick,
  onAddPromoCodeBtnClick,
}: Props) => {
  const { t } = useTranslation(["purchase", "common"]);

  const showGetQuoteButton = hasNoExistingPuchase && !isRatesAvailable;
  const showResumeButton = !hasNoExistingPuchase;
  const showLookUpWarrantyButton = showResumeButton;

  return (
    <div
      id="warranty-select-coverage-header"
      className="overflow-visible no-scrollbar relative bg-cover bg-center"
    >
      <div
        className={classNames(
          "relative max-w-page mx-auto flex items-center flex-col-reverse pt-10 pb-6 px-4 lg:px-5 lg:py-16 lg:flex-row-reverse",
          {
            "lg:pt-16 lg:pb-0": isRatesAvailable,
            "gap-4": !!vehicleCoverImage,
          }
        )}
      >
        <div className="flex-1 lg:basis-[40%] lg:px-0 md:px-28">
          <div className="w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
            <p className="text-text-light-100 font-semibold text-[2.25rem] md:text-[3.25rem] leading-[2.625rem] md:leading-[3.625rem]">
              {vehicleInfo ? (
                <Trans
                  t={t}
                  i18nKey="purchase:vehicle_qualifies_for_warranty"
                  values={{
                    vehicle: vehicleInfo,
                  }}
                  components={{
                    bold: <span className="font-semibold text-primary-bold" />,
                  }}
                />
              ) : (
                <Trans
                  t={t}
                  i18nKey="purchase:keep_that_used_ride_feeling_new"
                  components={{
                    bold: <span className="font-semibold text-primary-bold" />,
                  }}
                />
              )}
            </p>
          </div>

          <div className="flex flex-col gap-4 mt-4 lg:mt-6 w-full items-center justify-center text-center lg:text-left lg:items-start lg:justify-start">
            <h4>
              <Trans
                t={t}
                i18nKey="purchase:get_coverage_from_rightride"
                components={{
                  bold: <span className="font-semibold" />,
                }}
              />
            </h4>
            <p>{t("purchase:who_is_rightride")}</p>
          </div>

          <div className="flex flex-col gap-2 mt-8 justify-center text-center md:items-center lg:text-start lg:items-start lg:justify-start">
            <div className="grid grid-cols-1 gap-2">
              {showGetQuoteButton && (
                <Button
                  rightIcon={<ArrowRightIcon />}
                  onClick={() => onLookUpWarrantyBtnClick("Get a Quote")}
                >
                  {t("purchase:get_a_quote")}
                </Button>
              )}

              {showResumeButton && (
                <Button
                  rightIcon={<ArrowRightIcon />}
                  onClick={onResumeBtnCLick}
                >
                  {t("purchase:resume_my_purchase")}
                </Button>
              )}
              {!isRatesAvailable && (
                <Button fill="outline" onClick={onAddPromoCodeBtnClick}>
                  {t("purchase:i_have_a_promo_code")}
                </Button>
              )}
            </div>

            {showLookUpWarrantyButton && (
              <Button
                fill="link"
                className="underline pl-0 lg:text-start lg:w-3/4 xl:w-auto"
                rightIcon={<ArrowRightIcon />}
                onClick={() =>
                  onLookUpWarrantyBtnClick("Look up warranty options")
                }
              >
                {t("purchase:lookup_warranty_options")}
              </Button>
            )}
          </div>
        </div>
        <div className="flex-1 lg:basis-[60%] w-full relative">
          <VehicleCoverImageFrame
            vehicleCoverImage={vehicleCoverImage}
            vehicleDefaultImage={vehicleDefaultImage}
            bubblesImage={bubblesImage}
          />
        </div>
      </div>
    </div>
  );
};

export default WarrantySelectCoverageHeading;
