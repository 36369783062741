import { CloseIcon, SearchIcon } from "@common/components/icons";
import { Address } from "@common/types/Address";
import { KeyboardEvent, ForwardedRef, forwardRef, useRef } from "react";
import customerValidation from "@common/helpers/validations/customerValidation";
import Form from "./form";
import { Status } from "./form/types";

type Props = {
  label?: string;
  placeHolder?: string;
  defaultValue?: string;
  feedback?: string;
  status?: Status;
  onChange: (address: Address | string) => void;
  onClear: () => void;
  onBlur: () => void;
  showShortAddress?: boolean;
};

const AddressAutocompleteInput = (
  {
    label,
    placeHolder,
    defaultValue,
    feedback,
    status,
    onChange,
    onClear,
    onBlur,
    showShortAddress = false,
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const autoCompleteRef = useRef<HTMLInputElement | null>(null);
  const isAutoCompleteCreated = useRef(false);

  /** Passes the raw string from input or user selcted Address obj from autocomplete dropdown to the onChange handler */
  const onAutocompleteAddressChange = () => {
    if (autoCompleteRef.current) {
      onChange(autoCompleteRef.current.value);
    }

    if (!isAutoCompleteCreated.current && autoCompleteRef.current) {
      isAutoCompleteCreated.current = true;
      const options = {
        fields: ["address_components", "geometry", "formatted_address"],
        componentRestrictions: { country: "ca" },
        strictBounds: false,
        types: ["address"],
      };

      const autoCompleteInst = new google.maps.places.Autocomplete(
        autoCompleteRef.current,
        options
      );

      autoCompleteInst.addListener("place_changed", () => {
        const place = autoCompleteInst.getPlace();

        if (place.address_components) {
          const addressComponents =
            place.address_components as google.maps.GeocoderAddressComponent[];

          const lat = place?.geometry?.location?.lat();
          const long = place?.geometry?.location?.lng();

          const selectedAddress = {
            number: "",
            suite: "",
            route: "",
            city: "",
            provinceAbbr: "",
            postalCode: "",
            latitude: lat || undefined,
            longitude: long || undefined,
          };

          for (let i = 0; i < addressComponents.length; i += 1) {
            const addressComponent = place.address_components[i];
            const addressType = addressComponent.types[0];

            switch (addressType) {
              case "street_number": {
                selectedAddress.number = addressComponent.short_name;
                break;
              }
              case "subpremise": {
                selectedAddress.suite = addressComponent.long_name;
                break;
              }
              case "route": {
                selectedAddress.route = addressComponent.long_name;
                break;
              }
              case "locality": {
                selectedAddress.city = addressComponent.long_name;
                break;
              }
              case "administrative_area_level_1": {
                selectedAddress.provinceAbbr = addressComponent.short_name;
                break;
              }
              case "postal_code": {
                selectedAddress.postalCode = addressComponent.short_name;
                break;
              }
              default: {
                break;
              }
            }
          }

          onChange({
            address1: `${selectedAddress.number} ${selectedAddress.route}`,
            address2: selectedAddress.suite,
            city: selectedAddress.city,
            provinceAbbr: selectedAddress.provinceAbbr,
            postalCode: selectedAddress.postalCode,
            latitude: selectedAddress.latitude,
            longitude: selectedAddress.longitude,
            streetNumber: selectedAddress.number,
            streetName: selectedAddress.route,
          });

          if (showShortAddress && autoCompleteRef.current) {
            autoCompleteRef.current.value = `${selectedAddress.number} ${selectedAddress.route}`;
          }
        }
      });
    }
  };

  const clearAddressInput = () => {
    if (autoCompleteRef.current) {
      autoCompleteRef.current.value = "";
    }
    onClear();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // This is to prevent the form from submitting when the user presses enter.
    //  Google maps state updates don't take place until after the form submits
    //  https://stackoverflow.com/questions/11388251/google-autocomplete-enter-to-select
    if (e.key === "Enter") {
      e.preventDefault();
      onAutocompleteAddressChange();
    }
  };

  return (
    <Form.Input
      id="streetAddress"
      autoComplete="street-address"
      ref={(inputRef) => {
        if (typeof ref === "function") {
          ref?.(inputRef);
        }
        autoCompleteRef.current = inputRef;
      }}
      label={label}
      defaultValue={defaultValue}
      placeholder={placeHolder}
      feedback={feedback}
      status={status}
      onChange={onAutocompleteAddressChange}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      leftIcon={<SearchIcon />}
      rightIcon={
        <CloseIcon className="cursor-pointer" onClick={clearAddressInput} />
      }
      rightIconClickable
      maxLength={customerValidation.streetAddress.maxLength}
      suppressHotjar
    />
  );
};

export default forwardRef<HTMLInputElement, Props>(AddressAutocompleteInput);
