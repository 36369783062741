import useAftermarketPurchase from "@modules/purchase/hooks/useAftermarketPurchase";
import useAftermarketPurchaseFromQuery from "@modules/purchase/hooks/useAftermarketPurchaseFromQuery";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { gtmPushData } from "@common/helpers/gtm";
import useSourceInfoFromQuery from "@modules/query-string/hooks/useSourceInfoFromQuery";
import useBuyerInfoFromQuery from "@modules/query-string/hooks/useBuyerInfoFromQuery";
import useExtendedWarrantyDefaultRates from "@modules/purchase/hooks/useExtendedWarrantyDefaultRates";
import useIsBuyerProvinceActiveForAftermarket from "@modules/purchase/hooks/useIsBuyerProvinceActiveForAftermarket";
import { tryGetActiveAftermarketPurchase } from "@modules/purchase/types/Purchase";
import validateRequiredRatesCallFields from "@modules/purchase/helpers/validateRequiredRatesCallFields";
import { WarrantySelectCoverageLayout } from "@modules/cms/types/WarrantySelectCoverageLayout";
import { useWarrantyQuotePageContext } from "@modules/purchase/WarrantyQuotePageContext";
import GetBackgroundStyle from "@modules/cms/helpers/getBackgroundStyle";
import Button from "@common/components/Button";
import { useTranslation } from "next-i18next";
import { ExperienceResult } from "@modules/core/convert/hooks/useConvertExperience";
import { AftermarketDiscountConvertData } from "@modules/purchase/types/AftermarketDiscountConvertData";
import WarrantySelectCoverageCards from "./WarrantySelectCoverageCards";
import WarrantySelectCoverageHeading from "./WarrantySelectCoverageHeading";
import { CoverageSearchBy } from "./AvailableCoveragesModal";
import ApplyPromoCodeModal from "./ApplyPromoCodeModal";

interface WarrantySelectCoverageProps {
  layout: WarrantySelectCoverageLayout;
  convertResult?: ExperienceResult<AftermarketDiscountConvertData>;
}

const WarrantySelectCoverage = ({
  layout,
  convertResult,
}: WarrantySelectCoverageProps) => {
  const { showHeading, background } = layout;
  const router = useRouter();
  const { t } = useTranslation(["purchase"]);
  const { locale, query } = router;
  const viewMode = query.view === "quote" ? "quote" : undefined;
  const warrantyOptionsSectionRef = useRef<HTMLDivElement>(null);
  const [isAddPromoModalOpen, setIsAddPromoModalOpen] = useState(false);

  const aftermarketPurchaseFromQuery = useAftermarketPurchaseFromQuery();
  const { purchase: purchaseResponse } = useAftermarketPurchase();
  const purchase = tryGetActiveAftermarketPurchase(purchaseResponse);
  const { source: querySource } = useSourceInfoFromQuery();
  const source = purchase?.dealSource || querySource;

  const { province } = useBuyerInfoFromQuery();
  const { isProvinceActive, isProvincesLoading } =
    useIsBuyerProvinceActiveForAftermarket(purchase?.dealSource, province);

  const convertAftermarketData =
    convertResult?.data as AftermarketDiscountConvertData;

  const {
    setShowCoveragesModal,
    setCoveragesModalSearchType,
    onSelectCoverage,
    onResumePurchase,
    purchasedVehicle,
    isSubmitted,
  } = useWarrantyQuotePageContext();

  const handleOpenCoveragesModal = (
    element: string,
    searchType?: CoverageSearchBy
  ) => {
    gtmPushData({
      event: "ManualQuoteLookup",
      element,
      description: `${element} Button Clicked`,
      source: purchase?.dealSource || "",
    });

    setShowCoveragesModal(true);
    setCoveragesModalSearchType(searchType);
  };

  const {
    vin,
    mileage,
    year,
    make,
    model,
    trim,
    coverImage: coverImageParam,
    fuelType,
  } = aftermarketPurchaseFromQuery;

  const { data: extDefaultRates, isSuccess: isRatesAvailable } =
    useExtendedWarrantyDefaultRates(
      {
        vin: vin ?? "",
        odometer: mileage as number,
        province: province?.abbr || undefined,
        source: source ?? undefined,
        year: year?.toString() ?? "",
        make: make ?? "",
        model: model ?? "",
        trim: trim ?? "",
        fuelType: fuelType ?? "",
      },
      isProvincesLoading ? null : isProvinceActive,
      locale || "en"
    );

  const scrollToWarrantyOptionsSection = () => {
    warrantyOptionsSectionRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleCustomEvent = () => {
      scrollToWarrantyOptionsSection();
    };
    if (showHeading) {
      // Add an event listener for the custom event, if the layout has a heading
      window.addEventListener(
        "scrollToWarrantyOptionsSection",
        handleCustomEvent
      );
    }

    return () => {
      if (showHeading) {
        window.removeEventListener(
          "scrollToWarrantyOptionsSection",
          handleCustomEvent
        );
      }
    };
  }, [showHeading]);

  const getVehicleCoverImage = (): string => {
    if (!purchase) {
      return coverImageParam ?? "";
    }

    const existingVehicle = purchase.vehicle;
    const savedCoverImage = existingVehicle?.image;
    const isVinMatch = existingVehicle?.vin === vin;
    const areRequiredFieldsAvailable = validateRequiredRatesCallFields({
      vin,
      year: year ? year.toString() : "",
      make,
      model,
      odometer: mileage,
    });

    if (!areRequiredFieldsAvailable) {
      return savedCoverImage ?? "";
    }

    return isVinMatch ? savedCoverImage ?? "" : coverImageParam ?? "";
  };

  return (
    <>
      <div
        id="cms-warranty-select-coverage-section"
        style={
          isRatesAvailable
            ? GetBackgroundStyle(background)
            : { backgroundColor: background.backgroundColor ?? "transparent" }
        }
      >
        {showHeading && (
          <WarrantySelectCoverageHeading
            isRatesAvailable={isRatesAvailable}
            hasNoExistingPuchase={
              purchase === undefined || purchase.products.length === 0
            }
            vehicleInfo={purchasedVehicle}
            vehicleCoverImage={getVehicleCoverImage()}
            onResumeBtnCLick={onResumePurchase}
            onLookUpWarrantyBtnClick={handleOpenCoveragesModal}
            onAddPromoCodeBtnClick={() => setIsAddPromoModalOpen(true)}
          />
        )}

        {isRatesAvailable && (
          <div className="overflow-visible no-scrollbar relative bg-cover bg-center">
            <div className="max-w-page mx-auto pb-6 px-4 lg:px-5 lg:pt-2">
              <div
                ref={warrantyOptionsSectionRef}
                className="lg:pt-8 text-center"
              >
                {/** Warranty Select Section */}
                <WarrantySelectCoverageCards
                  coverages={extDefaultRates}
                  onSelectCoverage={onSelectCoverage}
                  onQuoteDifferentVehicle={(searchType) =>
                    handleOpenCoveragesModal(
                      "Quote a different vehicle",
                      searchType
                    )
                  }
                  showEstimateMessaging={vin == null}
                  disabled={isSubmitted}
                  viewMode={viewMode}
                  discountAmount={
                    purchase?.appliedPromoCode?.discountAmount ||
                    convertAftermarketData?.discountValue
                  }
                />
              </div>
            </div>
            <div className="flex justify-center lg:pb-14">
              <Button
                fill="outline"
                onClick={() => setIsAddPromoModalOpen(true)}
              >
                {t("purchase:i_have_a_promo_code")}
              </Button>
            </div>
          </div>
        )}
      </div>
      {purchase && isAddPromoModalOpen && (
        <ApplyPromoCodeModal
          isOpen={isAddPromoModalOpen}
          dealId={purchase.id}
          onClose={() => setIsAddPromoModalOpen(false)}
        />
      )}
    </>
  );
};

export default WarrantySelectCoverage;
