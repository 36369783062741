import {
  CircleFullIcon,
  CircleHalfIcon,
  CircleQuarterIcon,
  CircleThreeQuartersIcon,
} from "@common/components/icons";
import React, { ReactNode } from "react";
import { TranslationKeys } from "src/@types/i18next";

export type ApproximateBuyingDate = {
  id: number;
  name: TranslationKeys["purchase"];
  icon?: ReactNode;
};

export const approximateBuyingDates: ApproximateBuyingDate[] = [
  {
    id: 1,
    name: "purchase:in_the_next_few_days",
    icon: React.createElement(CircleQuarterIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
  {
    id: 2,
    name: "purchase:in_the_next_few_weeks",
    icon: React.createElement(CircleHalfIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
  {
    id: 3,
    name: "purchase:in_the_next_few_months",
    icon: React.createElement(CircleThreeQuartersIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
  {
    id: 4,
    name: "purchase:sometime_this_year",
    icon: React.createElement(CircleFullIcon, {
      className: "block text-blueZodiac m-auto w-6 h-6",
    }),
  },
];

export function getApproximateBuyingDateById(id: number) {
  return approximateBuyingDates.find((s) => s.id === id);
}
